footer {
  width: 100%;
  background-color: #195f78;
  color: white;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: Roboto, sans-serif;
  color: white;
}

footer h4 {
  font-size: 1rem;
  font-weight: 200;
}

footer .container {
  margin: 0;
  width: 100%;
  max-width: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0;
}

footer .container .acerca,
footer .container .contacto,
footer .container .paginas,
footer .container .blog-footer {
  margin: 0 45px;
}

footer h3 {
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 40px;
}

footer .paginas ul {
  padding-left: 20px;
  width: max-content;
  display: flex;
  flex-direction: column;
}

footer .paginas ul li {
  width: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 5px;
}

footer .paginas ul li a {
  color: white;
  transition: ease all 0.3s;
  font-size: 1rem;
  font-weight: 200;
}

footer .paginas ul li a:hover {
  color: goldenrod;
}

footer .paginas,
footer .blog-footer {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

footer .blog-footer .entries {
  display: flex;
  column-gap: 20px;
}

footer .blog-footer .entry {
  display: flex;
  width: 100%;
}

footer .blog-footer .entry .img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

footer .blog-footer .entry .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

footer .blog-footer .entry .title {
  width: 200px;
  display: flex;
  align-items: flex-start;
}

/*
Para las direcciones
*/

.ul_dir {
  list-style: none;
  text-align: center;
}

footer .container h3 {
  font-size: 1.3rem;
  font-family: "lato", serif;
}

.contacto a {
  color: white;
}

.numeros {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

footer a,
footer p,
footer .paginas ul li {
  font-size: 0.9rem;
}

.derechos-reservados {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.derechos-reservados__texto {
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
  color: white;
}

.seccion-links {
  display: flex;
  column-gap: 30px;
}

.footer_titulo--mayuscula::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
  footer {
    height: max-content;
    padding: 30px 40px;
  }

  footer .blog-footer .entries {
    flex-direction: column;
    row-gap: 20px;
  }

  footer .container {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    align-items: center;
  }

  footer .container h3 {
    width: max-content;
    margin: 0 auto 20px;
  }

  footer h4 {
    text-align: left;
  }

  footer .blog-footer,
  footer .paginas {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .derechos-reservados__texto {
    text-align: center;
  }

  footer .blog-footer .entry .img {
    flex: none;
  }

  footer .paginas ul li a {
    font-size: 1rem;
  }

  footer .container div {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 0;
  }

  footer .container div.paginas {
    text-align: left;
  }
}
